import React from 'react';
import './GetInTouch.scss';

import PhoneIcon from '../../../assets/icons/PhoneNew.png';
import MailIcon from '../../../assets/icons/EmailNew.png';
import LocationIcon from '../../../assets/icons/LocationNew.png';
import MediaIcon from '../../../assets/icons/ContactNew.png';

const GetInTouch = () => {
    const position = { lat: 44.0171626, lng: -79.386195 };  // Lat, Lng for the location

    return (
        <div className="get-in-touch-main">
            <div className="get-in-touch">
                <div className="get-in-touch__container">
                    <div className="get-in-touch__info">
                        <h1 className='get-in-touch__header'>Get in Touch</h1>
                        <div className="get-in-touch__list">
                            <div className="get-in-touch__list-item">
                                <img src={PhoneIcon} className='get-in-touch__list-image' alt="Icon of a phone." />
                                <p className='get-in-touch__list-text'>(905) 727-3948</p>
                            </div>
                            <div className="get-in-touch__list-item">
                                <img src={MailIcon} className='get-in-touch__list-image' alt="Icon of a letter." />
                                <p className='get-in-touch__list-text'>info@RamuddenGlobal-na.com</p>
                            </div>
                            <div className="get-in-touch__list-item">
                                <img src={LocationIcon} className='get-in-touch__list-image' alt="Icon of a location pin." />
                                <p className='get-in-touch__list-text'>180 Ram Forest Road<br/>Whitchurch-Stouffville, Ontario<br/>L4A 2G8</p>
                            </div>
                            <div className="get-in-touch__list-item">
                                <img src={MediaIcon} className='get-in-touch__list-image' alt="Icon of a person." />
                                <p className='get-in-touch__list-text'><b className='bold-text'>Media Inquiries</b><br/>mediarelations@RamuddenGlobal-na.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="get-in-touch__right">
                        <iframe
                            width="100%"
                            height="400px"
                            frameborder="0"
                            style={{border:0}}
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCqbNCVJf9P7ykL4qUc5Lnmx135sBiqdIE&q=${position.lat},${position.lng}`}
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </div>
            <div className="get-in-touch__mobile-map">
                <iframe
                    width="100%"
                    height="300px"
                    frameborder="0"
                    style={{border:0}}
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCqbNCVJf9P7ykL4qUc5Lnmx135sBiqdIE&q=${position.lat},${position.lng}`}
                    allowfullscreen>
                </iframe>
            </div>
        </div>
    );
}

export default GetInTouch;
