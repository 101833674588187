import React from "react";
import "./TermsOfUse.scss";

import Footer from "../../components/Footer/Footer";

const TermsOfUse = () => {
  return (
    <>
      <div className="terms-container">
        <div>
          <h1 className="terms-title">RSG International Terms of Use</h1>
          <p className="terms-date">Last Updated: March 21st, 2024</p>
        </div>

        <div className="terms-section">
          <p className="terms-intro">
            Welcome to RSG International Corp. ("RSG International", "us", "we",
            or "our"). These Terms of Use govern your use of our website
            www.rsgint.com (the "Site"). By accessing or using the Site, you
            agree to be bound by these Terms of Use. If you do not agree with
            these Terms of Use, please do not access or use the Site.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">1. Intellectual Property Rights</h2>
          <p className="terms-text">
            The content on this Site, including but not limited to text,
            graphics, logos, images, audio clips, and software, is the property
            of RSG International and is protected by Canadian and international
            copyright laws. You may not use, reproduce, modify, or distribute
            any content from this Site without prior written permission from RSG
            International.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">2. Restrictions</h2>
          <p className="terms-text">You agree not to:</p>
          <ul className="terms-list">
            <li>Use the Site for any unlawful purpose;</li>
            <li>
              Impersonate any person or entity or falsely state or misrepresent
              your affiliation with a person or entity;
            </li>
            <li>
              Interfere with or disrupt the operation of the Site or servers or
              networks connected to the Site;
            </li>
            <li>
              Attempt to gain unauthorized access to any portion of the Site or
              any other systems or networks connected to the Site.
            </li>
          </ul>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">3. Privacy</h2>
          <p className="terms-text">
            Your use of the Site is subject to our Privacy Policy. Please review
            our{" "}
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="terms-link"
            >
              Privacy Policy
            </a>{" "}
            for information on how we collect, use, and disclose your personal
            information.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">4. No Warranties</h2>
          <p className="terms-text">
            The information provided on the Site is for general informational
            purposes only. We make no representations or warranties of any kind,
            express or implied, about the completeness, accuracy, reliability,
            suitability, or availability of the information, products, services,
            or related graphics contained on the Site for any purpose.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">5. Limited Liability</h2>
          <p className="terms-text">
            In no event shall RSG International be liable for any loss or damage
            arising out of or in connection with your use of the Site. This
            includes, but is not limited to, direct, indirect, incidental,
            consequential, or punitive damages.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">6. Indemnification</h2>
          <p className="terms-text">
            You agree to indemnify and hold harmless RSG International, its
            affiliates, officers, directors, employees, agents, and licensors
            from and against any and all claims, liabilities, damages, losses,
            costs, expenses, or fees (including reasonable attorneys' fees)
            arising from or relating to your use of the Site or any violation of
            these Terms of Use.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">7. Severability</h2>
          <p className="terms-text">
            If any provision of these Terms of Use is found to be invalid,
            illegal, or unenforceable, the remaining provisions shall continue
            in full force and effect to the fullest extent permitted by law.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">8. Variation of Terms</h2>
          <p className="terms-text">
            We reserve the right to modify or replace these Terms of Use at any
            time. Any changes will be effective immediately upon posting on the
            Site. Your continued use of the Site after any such changes
            constitutes your acceptance of the new Terms of Use.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">9. Assignment</h2>
          <p className="terms-text">
            You may not assign, transfer, or sublicense any of your rights or
            obligations under these Terms of Use without the prior written
            consent of RSG International. We may assign, transfer, or delegate
            any of our rights and obligations under these Terms of Use without
            notice.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">10. Entire Agreement</h2>
          <p className="terms-text">
            These Terms of Use constitute the entire agreement between you and
            RSG International regarding your use of the Site and supersede all
            prior or contemporaneous agreements and understandings, whether
            written or oral.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">11. Governing Law and Jurisdiction</h2>
          <p className="terms-text">
            These Terms of Use shall be governed by and construed in accordance
            with the laws of the Province of Ontario and the federal laws of
            Canada applicable therein. You agree to submit to the exclusive
            jurisdiction of the courts located in the Province of Ontario for
            the resolution of any disputes arising out of or related to these
            Terms of Use or your use of the Site.
          </p>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">12. Contact Information</h2>
          <p className="terms-text">
            If you have any questions or concerns about these Terms of Use,
            please contact us at:
          </p>
          <address className="terms-contact">
            RSG International Corp.
            <br />
            180 Ram Forest Road,
            <br />
            Whitchurch-Stouffville, Ontario L4A 2G8
            <br />
            Email:{" "}
            <a href="mailto:mediarelations@RamuddenGlobal-na.com">
            mediarelations@RamuddenGlobal-na.com
            </a>
          </address>
        </div>

        <div className="terms-section">
          <h2 className="terms-subtitle">13. Right to Alter Terms of Use</h2>
          <p className="terms-text">
            We reserve the right to modify or update these Terms of Use at any
            time without prior notice. Please review these Terms of Use
            periodically for any changes. Your continued use of the Site after
            the posting of changes constitutes your acceptance of such changes.
            <br></br>
            <br></br>
            By accessing or using the Site, you agree to be bound by these Terms
            of Use. If you do not agree to these Terms of Use, please do not
            access or use the Site.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
